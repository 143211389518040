import { mainAxios } from "../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "./mainService";

export const getProductosAseguradora = async (params) => {
    try {
        const serverResponse = await mainAxios.post(`/v1/productoAseguradora/list`, params, getDefaultHeaders());
        return serverResponse.data.data;
    } catch (error) {
        throw new Error(getErrorMessage(error));
    }
}